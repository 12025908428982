<template>
    <v-tour name="editTour" :steps="editSteps" :callbacks="myCallbacks"></v-tour>
</template>

<script>
  import {db} from "@/firebase"
  import { mapGetters } from 'vuex'

  export default {
    name: 'edit-tour',
    props:['steps'],
    computed:{
      ...mapGetters({
        user:'GET_USER_ID',
      }),
    },
    data () {
      return {
        myCallbacks: {
          // onPreviousStep: this.myCustomPreviousStepCallback,
          // onNextStep: this.myCustomNextStepCallback
          onStop: this.setModalCookie,
        },
        editSteps: [
          {
            target: '#add-step-button',  // We're using document.querySelector() under the hood
            header: {
              title: '1/3 Create Steps',
            },
            content: `Enter the time here and click "add step" to create a new break point in the video.`,
            params: {
              placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            }
          },
          {
            target: '#card_1',
            header: {
              title: '2/3 Edit Steps',
            },
            content: "You can change the step's time, title, description, and whether it pauses or not."
          },
          {
            target: '[data-v-step="options"]',
            header: {
              title: '3/3 Publish Options',
            },
            content: 'Keep your video Private or Publish it out to world, and set some options to make the experience your own.',
            params: {
              placement: 'bottom' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            }
          }
        ]
      }
    },
    methods:{
      setModalCookie(){
        let vThis = this;
        let dbRef = db.collection('users').doc(this.user);
        dbRef.update({'showCreateTour':false}).then(()=>{
          vThis.dialog = false;
        });
      }
    },
    mounted: function () {
      this.$tours['editTour'].start()
    }
  }
</script>
